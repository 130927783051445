<template>
  <div>
    <v-hover
      v-slot:default="{ hover }"
    >
    <v-card outlined tile :class="{ 'on-hover': hover }">
      <v-list-item>
        <!-- <v-list-item-action></v-list-item-action> -->
        <v-list-item-action>
          <v-img
            max-width="60"
            max-height="80"
            v-if="mediaItem.imgUrl"
            :src="mediaItem.imgUrl"
          ></v-img>
          <v-img v-else
            max-width="60"
            max-height="80"
            src="@/assets/library/mp3.png"
          ></v-img>
        </v-list-item-action>
        <v-list-item-content class="mx-3">
          <v-list-item-subtitle>
            <span v-if="mediaItem.type === 'A'" class="primary--text">Audio</span>
            <span v-else class="red--text">Youtube</span>
          </v-list-item-subtitle>
          <v-list-item-title>{{ mediaItem.title }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-row>
              <v-col cols="4">
                <div>
                  <v-icon>mdi-folder</v-icon>
                  <span style="vertical-align: middle"> {{ mediaItem.folder }}</span>
                </div>
              </v-col>
              <v-col cols="4" align-self="center">
                <div>
                  <v-icon>mdi-clock-outline</v-icon>
                  <span style="vertical-align: middle"> {{ mediaItem.dur }}</span>
                </div>
              </v-col>
              <v-col cols="4" align-self="center">
                <div v-if="mediaItem.readCnt > 0">
                  <v-icon color="info">mdi-eye-check</v-icon>
                </div>
                <div v-else>
                  <v-icon>mdi-eye-off-outline</v-icon>
                </div>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            tile
            class="primary"
            v-if="mediaItem.audioFiles.length > 0 || mediaItem.yUrl"
            @click="preview"
          >
            Reading
          </v-btn>
          <v-btn
            tile
            class="grey"
            disabled
            v-else
          >
            Reading
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>
    </v-hover>
  </div>
</template>
<script>
export default {
  props: ['mediaItem'],
  data () {
    return {
      views: 0
    }
  },
  methods: {
    preview () {
      this.$emit('preview', this.mediaItem)
    }
  }
}
</script>
<style scoped>

.on-hover {
  border: 1px solid #4187F9;
}
</style>
