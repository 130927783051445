<template>
  <div justify="center">
    <v-dialog
      :value="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      style="overflow: hidden"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Reading</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- <v-btn text dark @click="popupDoc">
              새창 보기
            </v-btn> -->
            <v-btn icon dark @click="closeDialog">
              <v-icon>mdi-exit-to-app</v-icon>닫기
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div v-if="!youtubeLoaded">
          <v-row no-gutters>
            <v-col cols="3">
              <v-card tile elevation="0">
                <v-list-item></v-list-item>
                <v-list-item class="d-flex justify-center">
                  <v-img
                    max-width="120"
                    max-height="150"
                    v-if="mediaItem.imgUrl"
                    :src="mediaItem.imgUrl"
                  ></v-img>
                  <v-img v-else
                    max-width="70"
                    max-height="100"
                    src="@/assets/library/mp3.png"
                  ></v-img>
                </v-list-item>
                <v-list-item></v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>제목</v-list-item-subtitle>
                    <v-list-item-title>{{ mediaItem.title}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>폴더</v-list-item-subtitle>
                    <v-list-item-title>{{ mediaItem.folder}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>

              <v-card elevation="0">
                <v-card-subtitle>Playlists ({{ mediaItem.audioFiles.length }})</v-card-subtitle>
                <v-virtual-scroll
                  :items="mediaItem.audioFiles"
                  :item-height="30"
                  height="200"
                >
                  <template v-slot="{ index }">
                    <v-list-item @click="customPlay(index)">
                      <v-list-item-content>
                        <v-list-item-title
                           :class="index == playingIndex ? 'playing-font' : 'nonplaying-font'"
                        >Play [{{ index + 1 }}/{{ mediaItem.audioFiles.length }}] clip</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>

              </v-card>
              <v-card tile elevation="0" style="position: fixed;left: 0;bottom: 10px;" width="25%">
                <v-list-item >
                  <audio controls id="audioControl" style="width:100%" controlsList="nodownload"></audio>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="9">
              <div style="background-color: white" v-if="pdfUrl">
                <v-card tile elevation="0">
                  <iframe
                    id="iframeContent"
                    :src="pdfUrl"
                    width="100%"
                    :height="docHeight"
                    type="application/pdf"
                    frameborder="0"
                    @load="iframeStyle"
                  ></iframe>
                </v-card>
              </div>
              <div style="background-color: white" v-else>
                <v-card tile elevation="0">
                  <!-- <v-list-item style="position: fixed;top:50%;left:50%;">
                    <v-list-item-title class="text-center" >
                      PDF가 없습니다 <v-icon>mdi-emoticon-sad-outline</v-icon>
                    </v-list-item-title>
                  </v-list-item> -->
                  <v-list-item>
                    <v-list-item-title style="position: fixed;top:50%;left:50%;">
                      PDF가 없습니다. <v-icon large>mdi-emoticon-sad-outline</v-icon>
                    </v-list-item-title>
                  </v-list-item>
                </v-card>
              </div>
            </v-col>

          </v-row>
        </div>
        <v-card tile v-else>
          <v-list>
            <v-list-item>
              <youtube :video-id="playbackYoutubeId" ref="youtube" @playing="playing" width="100%" :height="youtubeHeight"></youtube>
            </v-list-item>
          </v-list>
        </v-card>

      </v-card>
    </v-dialog>
  </div>

</template>
<script>
export default {
  props: ['dialog', 'mediaItem', 'docHeight'],
  data () {
    return {
      loaded: false,
      youtubeLoaded: false,
      youtubeHeight: 600,
      pdfUrl: '',
      fitSize: '#toolbar=0&navpanes=0&statusbar=0',
      iframe: {
        src: window.location.href,
        style: {},
        wrapperStyle: {}
      },
      playbackYoutubeId: '',
      playingIndex: 0,
      nextPlayIndex: 0
    }
  },
  watch: {
    dialog () {
      if (this.dialog) {
        if (this.mediaItem.pdfFileUrl) {
          this.pdfUrl = this.mediaItem.pdfFileUrl + this.fitSize
        }
        this.loaded = true

        if (this.mediaItem.type === 'A') {
          this.youtubeLoaded = false
          this.playbackYoutubeId = ''
          this.loadAudio(0)
        } else {
          this.playbackYoutubeId = this.getVideoId(this.mediaItem.yUrl)
          this.youtubeHeight = this.docHeight - 16
          this.youtubeLoaded = true
        }
      } else {
        this.pdfUrl = ''
      }
    }
  },
  computed: {
    player () {
      return this.$refs.youtube.player
    }
  },
  mounted () {
    document.addEventListener('contextmenu', function (e) {
      e.preventDefault()
    }, false)
  },
  methods: {
    closeDialog () {
      let currTime = 0
      const audio = document.getElementById('audioControl')
      if (audio) {
        currTime = audio.currentTime
        audio.pause()
        audio.currentTime = 0
        audio.src = ''
      }
      this.playbackYoutubeId = ''

      this.$emit('closeDialog', this.mediaItem, currTime)
    },
    iframeStyle () {
      const frame = document.getElementById('iframeContent')
      frame.oncontextmenu = function () {
        // console.log('iframe right click ')
        return false
      }
    },
    getVideoId (url) {
      if (!url) return ''

      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = url.match(regExp)
      return (match && match[7].length === 11) ? match[7] : false
    },
    async playVideo () {
      await this.player.playVideo()
    },
    playing () {
      // watching log

    },
    popupDoc () {
      window.open(this.mediaItem.pdfFileUrl, 'OpenPDF')
    },
    loadAudio (idx) {
      try {
        this.playingIndex = idx
        const myAudio = document.getElementById('audioControl')
        // myAudio.removeEventListener('ended', () => { console.log('clear event') })
        if (this.playingIndex < this.mediaItem.audioFiles.length) {
          myAudio.src = this.mediaItem.audioFiles[this.playingIndex].url
          myAudio.load()
          this.nextPlayIndex = idx + 1
          myAudio.play()
        }

        myAudio.addEventListener('ended', () => {
          if (this.nextPlayIndex >= this.mediaItem.audioFiles.length) return
          this.playingIndex = this.nextPlayIndex
          myAudio.src = this.mediaItem.audioFiles[this.nextPlayIndex].url
          myAudio.load()
          this.nextPlayIndex = this.nextPlayIndex + 1
          myAudio.play()
        })
      } catch (e) {
        // console.log(e.message)
        setTimeout(() => {
          this.loadAudio(0)
        }, 1000)
      }
    },
    customPlay (idx) {
      this.playingIndex = idx
      const myAudio = document.getElementById('audioControl')
      if (this.playingIndex < this.mediaItem.audioFiles.length) {
        myAudio.src = this.mediaItem.audioFiles[this.playingIndex].url
        myAudio.load()
        this.nextPlayIndex = idx + 1
        myAudio.play()
      }
    }
  }
}
</script>
<style scoped>
.playing-font {
  color: #1c75bc;
}
.nonplaying-font {
  color: rgba(0, 0, 0, 0.87);
}
</style>
