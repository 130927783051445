<template>
  <div>
    <v-container v-if="!loaded" fluid>
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile>
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
          </v-card>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader type="list-item-avatar-three-line" v-for="i in 3" :key="i"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else fluid>
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <div>

                    <span>
                      <v-menu
                        offset-y
                        close-on-content-click
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="secondary"
                            text
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ currentFolder }} &#9660;
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="moveToFolder('', '_ALL')">
                            <v-list-item-title>
                              전체
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-for="(item, index) in folders"
                            :key="index"
                            @click="moveToFolder(item.id, item.name)"
                          >
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </span>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-row class="mt-1">
            <v-col cols="12">
              <!-- <v-card outlined tile height="120" v-if="mediaItems.length <= 0"> -->
              <!-- <div v-if="mediaItems"> -->
                <div v-if="mediaItems.length > 0">
                  <media-item
                    class="pb-3"
                    v-for="(item, index) in mediaItems"
                    :key="index"
                    :mediaItem="item"
                    @preview="preview"
                  ></media-item>
                </div>
                <v-card outlined tile height="140" v-else>
                  <v-list-item>
                    <v-list-item-title class="d-flex justify-center mt-13" style="align-items: center">
                      등록된 파일이 없습니다.<v-icon large>mdi-emoticon-sad-outline</v-icon>
                    </v-list-item-title>
                  </v-list-item>
                </v-card>
              <!-- </div> -->
              <v-row >
                <v-col cols="12" class="pt-0">
                  <v-card outlined tile v-if="this.paging.currentPage < this.paging.totalPage">
                    <v-btn block tile text @click="more">
                      더보기({{ this.paging.currentPage }} / {{ this.paging.totalPage }})<v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="moreOverlay">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>

    <play-card
      :dialog="playDialog"
      :mediaItem="selectedPreviewItem"
      :docHeight="playDocHeight"
      @closeDialog="closeDialog"
    ></play-card>
  </div>
</template>
<script>
import MediaItem from '@/components/widgets/MediaItemCardStd'
import PlayCard from '@/components/widgets/StdPlayCard'
const LIMIT = 10

export default {
  components: { MediaItem, PlayCard },
  data () {
    return {
      mediaItems: [],
      mediaItemsCnt: {},
      folders: [],
      currentFolder: '전체',
      currentFolderId: '',
      loaded: false,
      selectedPreviewItem: {},
      paging: {
        currentPage: 1,
        sizePerPage: LIMIT,
        totalPage: 1
      },
      // new
      playDialog: false,
      playDocHeight: 560,
      moreOverlay: false
    }
  },
  created () {
    this.moveToFolder('', '_ALL')
  },
  methods: {
    moveToFolder (folderId, folderName) {
      this.currentFolderId = folderId
      this.currentFolder = (folderName === '_ALL') ? '전체' : folderName
      // const puser = JSON.parse(localStorage.getItem('stduser'))
      // console.log('academy', puser.academy)
      this.loaded = false
      this.resetPage()
      this.$axios.get('manage/library', {
        params: {
          ac: this.$store.state.stdUser.user.academy,
          std: this.$store.state.stdUser.user.stddoc,
          pageSize: LIMIT,
          folder: (folderName === '_ALL') ? '' : folderId
        }
      })
        .then((r) => {
          this.folders = r.data.folders
          this.mediaItems = r.data.libraries
          this.mediaItemsCnt = r.data.cnt[0]

          // console.log('res folders', this.folders)
          // console.log('res mediaItems', this.mediaItems)
          // console.log('res mediaItemsCnt', this.mediaItemsCnt)

          this.setPage((folderName === '_ALL') ? 'all' : folderId)

          this.loaded = true
        })
        .catch((e) => {
          this.$store.commit('pop', { msg: '연결이 끊겼습니다. 다시 로그인 해주세요', color: 'warning' })
        })
    },
    setPage (folderId) {
      this.paging.currentPage = 1
      this.paging.totalPage = this.mediaItemsCnt[folderId]

      if (this.mediaItemsCnt[folderId]) {
        this.paging.totalPage = parseInt(this.mediaItemsCnt[folderId] / this.paging.sizePerPage)
        const r = this.mediaItemsCnt[folderId] % this.paging.sizePerPage
        if (r > 0) {
          this.paging.totalPage = this.paging.totalPage + 1
        }
      }
    },
    resetPage () {
      this.paging.currentPage = 1
      this.paging.sizePerPage = LIMIT
      this.paging.totalPage = 1
    },
    preview (item) {
      // Firefox, IE11 체크해서 실행 안하게
      const isFirefox = typeof InstallTrigger !== 'undefined'
      const isIE = /* @cc_on!@ */false || !!document.documentMode
      if (isFirefox || isIE) {
        this.$store.commit('pop', { msg: '크롬 또는 엣지로 접속하세요', color: 'warning' })
        return
      }

      this.selectedPreviewItem = item
      this.playDialog = true
      // this.playDocHeight = window.innerHeight - 180
      this.playDocHeight = window.innerHeight - 70
      // console.log('playDocHeight', this.playDocHeight)
    },
    previewPDF (item) {
      if (item.pdfFileUrl) window.open(item.pdfFileUrl, 'OpenPDF')
    },
    async more () {
      if (this.paging.currentPage >= this.paging.totalPage) return
      this.moreOverlay = true
      this.$axios.get('manage/library', {
        params: {
          ac: this.$store.state.stdUser.user.academy,
          std: this.$store.state.stdUser.user.stddoc,
          pageSize: LIMIT,
          folder: (this.currentFolder === '전체') ? '' : this.currentFolderId,
          offset: Number(this.paging.currentPage) * LIMIT
        }
      })
        .then((r) => {
          this.folders = r.data.folders
          // this.mediaItems = r.data.libraries
          this.mediaItemsCnt = r.data.cnt[0]
          this.paging.currentPage = this.paging.currentPage + 1

          const resLibrary = r.data.libraries
          resLibrary.forEach(item => {
            this.mediaItems.push(item)
          })

          this.moreOverlay = false

          // console.log('res folders', this.folders)
          // console.log('res mediaItems', this.mediaItems)
          // console.log('res mediaItemsCnt', this.mediaItemsCnt)
        })
        .catch((e) => {
          this.$store.commit('pop', { msg: '연결이 끊겼습니다. 다시 로그인 해주세요', color: 'warning' })
        })
    },
    closeDialog (item, currTime) {
      this.playDialog = false
      // console.log(item, currTime, Math.floor(currTime))
      this.$axios.post('manage/library/read', {
        ac: this.$store.state.stdUser.user.academy,
        std: this.$store.state.stdUser.user.stddoc,
        read: item.id,
        dur: Math.floor(currTime)
      })
        .then((r) => {
          // console.log(r.data)
        })
        .catch((e) => {
          this.$store.commit('pop', { msg: '연결이 끊겼습니다. 다시 로그인 해주세요', color: 'warning' })
        })
    }
  }
}
</script>
